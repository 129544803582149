<template>
  <div class="submit-con">
    <div class="pv-box">
      <div class="pv-box-header">
        <div class="pv-box-header-left">课程简介</div>
        <div class="pv-box-header-right">
          <i
            class="el-icon-close delete"
            @click="hideVideo"
            :style="{ cursor: 'pointer' }"
          ></i>
        </div>
      </div>

      <el-collapse v-model="activeName" accordion @change="handleChange">
        <el-collapse-item
          :name="`${index + 1}`"
          :disabled="activeName == `${index + 1}`"
          v-for="(item, index) in vedioList"
          :key="index"
        >
          <template slot="title">
            <img src="@/assets/img/play.png" class="pv-title-img" />
            {{ item.name }}
          </template>
          <div class="video-box">
            <div class="video-body" :id="`polyvideo${index + 1}`"></div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>
<script>
import { getClassListion } from "@/service";

export default {
  name: "polyVideo",
  props: {
    br: {
      type: [String, Number],
    },
    title: {
      type: String,
      default: "课程简介 & 免费试听",
    },
  },
  data() {
    return {
      vodPlayerJs: "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
      vid: "",
      activeName: "1",
      player: null,
      vedioList: [], // 视频列表
      showVideo: false,
    };
  },
  methods: {
    initVideo() {
      this.$nextTick(() => {
        this.loadPlayerScript(this.getInfos);
      });
    },
    hideVideo() {
      this.player.destroy();
      this.$emit("closeVideo");
    },
    closeVideo() {
      // console.log('11');
      // this.showVideo = false;
      this.player.destroy();
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    handleChange(val) {
      console.log("val", val);
      if (val == "") {
        this.activeName = "1";
      }
      if (val) {
        if (val === this.activeName) {
          this.activeName = val;
        }
        document.getElementById("polyvideo" + val).innerHTML = "";
        this.loadPlayer(val);
      }
    },
    loadPlayer(id) {
      if (this.player) {
        this.player.destroy();
        this.loadPlayerScript(() => {
          this.vId = this.vedioList[this.activeName - 1].url;
        });
      }
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#polyvideo" + id,
        width: 800,
        height: 400,
        vid: this.vId,
      });
    },
    getInfos() {
      getClassListion(this.br)
        .then((res) => {
          if (res.result && res.result.length) {
            this.vedioList = res.result;
            this.vId = res.result[this.activeName - 1].url;
            setTimeout(() => {
              this.loadPlayer(this.activeName);
            }, 300);
          }
        })
        .catch((err) => {
          console.log("courseInfo", err);
        });
    },
  },
  mounted() {
    this.initVideo();
  },
  destroyed() {
    if (this.player) {
      this.player.destroy();
    }
  },
};
</script>

<style scoped>
.submit-con .el-collapse {
  width: 800px;
  background-color: #2b3039 !important;
}

.submit-con .el-collapse-item__wrap {
  background-color: black !important;
}

.submit-con .el-collapse {
  border-top: 0 solid #ebeef5 !important;
  border-bottom: 0 solid #ebeef5 !important;
}

.submit-con .el-collapse-item__header {
  height: 48px !important;
  line-height: 48px !important;
  background-color: #2b3039 !important;
  color: #fff !important;
  border-bottom: 0 solid #666 !important;
  font-size: 14px !important;
  padding-left: 20px !important;
}

.submit-con .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.submit-con .el-collapse-item__wrap {
  border-bottom: 0 solid #ebeef5 !important;
}

.el-collapse-item.is-disabled .el-collapse-item__header {
  cursor: pointer !important;
}
</style>
<style scoped>
@import url("../../../assets/css/courses/common.css");

.pv-box {
  background: #2b3039 !important;
  padding: 10px;
  border-radius: 8px;
}

.pv-box-header {
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 800px;
}

.pv-box-header-left {
  padding-left: 5px;
  font-size: 16px;
}

.pv-box-header-right {
  padding-right: 5px;
  font-size: 20px;
}

.pv-title-img {
  height: 14px;
  width: 14px;
  margin-right: 10px;
}
</style>
