<template>
  <div class="page-select">
    <el-dropdown
      class="ban-el-dropdown"
      @command="handleCommand"
      placement="bottom"
      style="width: 100%"
    >
      <div class="select-hd">
        <div class="ui-cell__bd">
          <img src="@/assets/images/timu/bei.png" alt="" />
          <div class="h">北美求职</div>
          <div class="vl">{{ bantxt }}</div>
        </div>
        <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
      </div>
      <!--      <el-dropdown-menu slot="dropdown" class="ban-dropdown">-->
      <!--        <el-dropdown-item-->
      <!--          command="北美SDE算法速成班"-->
      <!--          class="nav-dropdown-item"-->
      <!--          :class="{ active: br === 3 }"-->
      <!--        >-->
      <!--          <div @mouseenter="brenter(3)">-->
      <!--            <div class="h3">北美SDE算法速成班</div>-->
      <!--            <p>零基础完整体系，2个月突破算法</p>-->
      <!--          </div>-->
      <!--        </el-dropdown-item>-->

      <!--        <el-dropdown-item-->
      <!--          command="北美SDE求职速成班"-->
      <!--          class="nav-dropdown-item"-->
      <!--          :class="{ active: br === 6 }"-->
      <!--        >-->
      <!--          <div @mouseenter="brenter(6)">-->
      <!--            <div class="h3">北美SDE求职速成班</div>-->
      <!--            <p>北美求职速成体系，FMAG级别突击面试</p>-->
      <!--          </div>-->
      <!--        </el-dropdown-item>-->
      <!--        <el-dropdown-item-->
      <!--          command="北美SDE求职旗舰班"-->
      <!--          class="nav-dropdown-item"-->
      <!--          :class="{ active: br === 7 }"-->
      <!--        >-->
      <!--          <div @mouseenter="brenter(7)">-->
      <!--            <div class="h3">北美SDE求职旗舰班</div>-->
      <!--            <p>最强北美课程体系，全程1v1服务体系</p>-->
      <!--          </div>-->
      <!--        </el-dropdown-item>-->
      <!--      </el-dropdown-menu>-->
    </el-dropdown>
  </div>
</template>
<script>
export default {
  name: "pageSelect",
  data() {
    return {};
  },
  props: {
    br: {
      type: [String, Number],
    },
    bantxt: {
      type: String,
    },
  },
  methods: {
    brenter: function (e) {
      this.$emit("changebr", e);
    },
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
  },
};
</script>
<style scoped>
.page-select {
  display: inline-block;
  border: 2px solid #fff;
  border-radius: 4px;
  margin-bottom: 37px;
  cursor: default;
  background-color: rgba(255, 255, 255, 0.4);
}
</style>