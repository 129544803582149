<template>
  <!-- 班课菜单 -->
  <el-menu
    ref="kzMenu"
    :default-active="currentIndex"
    :router="false"
    :unique-opened="true"
    active-text-color="#FA6400"
    background-color="#ffffff"
    class="p-navbar"
    mode="horizontal"
    text-color="#333"
    @select="menuSelect"
  >
    <el-menu-item
      v-for="item in menus"
      :key="item.index"
      :index="item.index && item.index.toString()"
      >{{ item.name }}

      <!-- <div v-if="item.tag == 'course_meal'">
        <div class="message">优惠</div>
      </div> -->
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "Menus",
  props: {
    menus: {},
    currentIndex: {
      type: Number | String,
      default: "0",
    },
  },
  data() {
    return {};
  },
  methods: {
    menuSelect(index, indexPath) {
      console.log("TabActiveIndexChange", this.menus, index);
      this.$emit("TabActiveIndexChange", this.menus[index].tag);
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    // console.log(this.m)
  },
  watch: {
    currentIndex(val) {
      console.log(val, "index变化");
    },
  },
};
</script>

<style scoped>
.p-navbar {
  display: flex;
  justify-content: center;
}

.top-sticky .p-navbar {
  justify-content: flex-start;
}

.message {
  background: url(../../assets/img/message.png) no-repeat;
  width: 36px;
  height: 16px;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 16px;
  line-height: 16px;
  font-size: 12px;
  color: #fff;
}
</style>
