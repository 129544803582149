<template>
  <div>
    <div class="searchQuestion">
      <el-input v-model="searchQuestionKey" />
      <el-button @click="searchQuestions">搜索</el-button>
    </div>

    <div class="searchResultList">
      <div v-for="(result, index) in results" :key="index" class="searchResult">
        <div class="resultTitle">
          <img src="@/assets/images/leet/question.png" />
          <span class="resultTitleText">{{ result.question }}</span>
        </div>
        <div class="resultContent" v-html="result.answer"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { searchQuestions } from "../../../service/index";

export default {
  name: "presalesConsultation",
  props: {
    objectId: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      results: [],
      searchQuestionKey: "",
    };
  },
  mounted() {
    this.searchQuestions();
  },
  methods: {
    searchQuestions() {
      searchQuestions(this.searchQuestionKey, this.objectId)
        .then((res) => {
          console.log(res);
          if (res.success) {
            this.results = res.result;
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.searchResult {
  padding-bottom: 18px;
}

.searchQuestion {
  width: 900px;
  margin: 50px auto;
}

.searchQuestion .el-input {
  width: calc(100% - 140px);
  margin-right: 10px;
}

.searchQuestion .el-button {
  background: rgba(250, 100, 0, 1);
  width: 120px;
  color: #fff;
}

.searchResultList {
  width: 1200px;
  margin: 0 auto;
}

.resultTitle {
  background: linear-gradient(
    315deg,
    rgba(229, 229, 229, 0.61) 0%,
    rgba(235, 236, 241, 0.6) 100%
  );
  border-radius: 6px;
  font-size: 18px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  line-height: 32px;
  vertical-align: top;
  height: 42px;
  line-height: 45px;
}

.resultTitle img {
  width: 30px;
  margin-left: 36px;
  padding-top: 9px;
  margin-right: 20px;
}

.resultTitleText {
  vertical-align: top;
}

.resultContent {
  padding: 0px 50px;
  font-size: 14px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  color: #8894ab;
  line-height: 25px;
  margin-top: 24px;
}
</style>
