<template>
  <el-dropdown class="nav-el-dropdown" @command="handleCommand">
    <span class="el-dropdown-link">
      <img src="../../../assets/images/scb/bei.png" alt="" /><span
        >北美求职</span
      >
      <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
    </span>
    <!-- <el-dropdown-menu slot="dropdown" class="ban-dropdown ban-dropdown3">
      <el-dropdown-item
        class="nav-dropdown-item"
        :class="{ active: dr === 3 }"
        command="北美SDE算法速成班"
      >
        <div @mouseenter="enter(3)">
          <div class="h3">北美SDE算法速成班</div>
          <p>零基础完整体系，2个月突破算法</p>
        </div>
      </el-dropdown-item>
      <el-dropdown-item
        class="nav-dropdown-item"
        :class="{ active: dr === 6 }"
        command="北美SDE求职速成班"
      >
        <div @mouseenter="enter(6)">
          <div class="h3">北美SDE求职速成班</div>
          <p>北美求职速成体系，FMAG级别突击面试</p>
        </div>
      </el-dropdown-item>
      <el-dropdown-item
        class="nav-dropdown-item"
        :class="{ active: dr === 7 }"
        command="北美SDE求职旗舰班"
      >
        <div @mouseenter="enter(7)">
          <div class="h3">北美SDE求职旗舰班</div>
          <p>最强北美课程体系，全程1v1服务体系</p>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu> -->
  </el-dropdown>
</template>
<script>
export default {
  name: "pageStickySelect",
  props: {
    dr: {
      type: [Number, String],
    },
  },
  data() {
    return {};
  },
  methods: {
    enter(e) {
      this.$emit("changedr", e);
    },
    handleCommand(command) {
      // this.$message('click on item ' + command);
      this.bantxt = command;
      console.log(command);
      switch (command) {
        case "10天LeetCode经典100题":
          this.$router.push("/course/1");
          break;
        case "求职面试算法班":
          this.$router.push("/course/4");
          break;
        case "求职面试刷题班":
          this.$router.push("/course/5");
          break;
        case "北美SDE算法速成班":
          this.$router.push("/course/3");
          break;
        case "LeetCode千题视频讲解":
          this.$router.push("/course/2");
          break;
        case "北美SDE求职速成班":
          this.$router.push("/course/6");
          break;
        case "北美SDE求职旗舰班":
          this.$router.push("/course/7");
          break;
      }
    },
  },
};
</script>
<style scoped>
.el-dropdown-link {
  cursor: default !important;
}
</style>