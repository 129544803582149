<template>
  <div class="upload-ad">
    <h3>LeetCode 1000道题目一道道讲解，最优解一题多解</h3>
    <h4>初学者即可一天10题，有经验者一天30题</h4>
    <span class="btn" style="cursor: pointer">立即体验</span>
  </div>
</template>
<script>
export default {
  name: "freeSystem",
  data() {
    return {};
  },
  methods: {
    freeVideo() {
      this.$router.push("/course/2");
    },
  },
};
</script>
<style scoped>
.btn {
  border-radius: 4px;
  padding: 0 !important;
}
</style>